var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-univ-intake"},[_c('v-alert',{attrs:{"dismissible":"","type":"error"},model:{value:(_vm.isShowAlert),callback:function ($$v) {_vm.isShowAlert=$$v},expression:"isShowAlert"}},[_vm._v(_vm._s(_vm.alertMsg))]),_c('validation-observer',{ref:"formUnivIntake",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{attrs:{"id":"form-university-intake"},on:{"submit":function($event){$event.preventDefault();return _vm.verifySubmit.apply(null, arguments)}}},[_c('v-row',{staticClass:"general-form",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"general-form__col",attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('validation-provider',{attrs:{"name":"Gelombang","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"title-14 mb-2"},[_vm._v("Gelombang")]),_c('v-text-field',{attrs:{"placeholder":"Gelombang","error-messages":errors,"clearable":"","type":"text","outlined":"","dense":""},model:{value:(_vm.univIntake),callback:function ($$v) {_vm.univIntake=$$v},expression:"univIntake"}})]}}],null,true)}),_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":"Tanggal Mulai","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"title-14 mb-2"},[_vm._v("Tanggal Mulai")]),_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.computedDateFormattedMomentjs(_vm.startDate),"placeholder":"Tanggal Mulai","error-messages":errors,"prepend-icon":"mdi-calendar","readonly":"","clearable":"","outlined":"","dense":""},on:{"click:clear":function($event){_vm.startDate = ''}}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true),model:{value:(_vm.menuStart),callback:function ($$v) {_vm.menuStart=$$v},expression:"menuStart"}},[_c('v-date-picker',{on:{"change":function($event){_vm.menuStart = false}},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1),_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":"Tanggal Berakhir","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"title-14 mb-2"},[_vm._v("Tanggal Berakhir")]),_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.computedDateFormattedMomentjs(_vm.endDate),"placeholder":"Tanggal Berakhir","error-messages":errors,"prepend-icon":"mdi-calendar","readonly":"","clearable":"","outlined":"","dense":""},on:{"click:clear":function($event){_vm.endDate = ''}}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true),model:{value:(_vm.menuEnd),callback:function ($$v) {_vm.menuEnd=$$v},expression:"menuEnd"}},[_c('v-date-picker',{on:{"change":function($event){_vm.menuEnd = false}},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1),(_vm.isEdit)?_c('validation-provider',{attrs:{"name":"Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"title-14 mb-2"},[_vm._v("Status")]),_c('v-select',{attrs:{"items":_vm.statusOpt,"error-messages":errors,"dense":"","outlined":""},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})]}}],null,true)}):_vm._e()],1)],1),_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"mr-2",attrs:{"outlined":"","min-width":120,"large":""},on:{"click":_vm.closeDialog}},[_vm._v(" BATAL ")]),_c('v-btn',{attrs:{"color":"primary","min-width":120,"type":"submit","form":"form-university-intake","large":"","disabled":_vm.isDisabled || invalid}},[_vm._v(" SIMPAN ")])],1)],1)]}}])}),_c('dialog-confirm',{ref:"dialogConfirm",attrs:{"info":_vm.info,"action":_vm.handleSubmit}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }