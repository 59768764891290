<template>
  <div class="registration-setting">
    <v-container>
      <div class="main-container">
        <v-card
          rounded="lg"
          class="pa-9"
          :class="{'py-9 px-4': $vuetify.breakpoint.smAndDown}"
        >
          <h2 class="mb-4">{{ 'dash.regist.setting.title' | text }}</h2>
          <v-alert
            dismissible
            type="error"
            v-model="isShowAlert"
          >{{ alertMsg }}</v-alert>
          <registration-data @handleAlert="handleAlert" />
        </v-card>
         <v-card
          rounded="lg"
          class="pa-9 mt-4"
          :class="{'py-9 px-4': $vuetify.breakpoint.smAndDown}"
        >
          <div class="d-flex justify-space-between">
            <h2 class="mb-4">{{ 'dash.regist.setting.period.title' | text }}</h2>
            <div class="">
              <v-btn 
                color="primary"
                :min-width="160"
                type="button"
                large
                @click="openDialog('add')"
              >
                TAMBAH
              </v-btn>
            </div>
          </div>
          <v-data-table
            :headers="headers"
            :items="univIntakeList"
            :loading="loading"
            :items-per-page="10"
            :footer-props="{'items-per-page-options':[10, 25, 50, 100]}"
            class="elevation-2 mt-4"
            @update:options="updateOpt"
          >
           <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                small
                color='primary'
                :min-width="120"
                :disabled="isDisabled(item)"
                @click="openDialog('edit', item)"
              >
                PERBARUI
              </v-btn>
            </template>
          </v-data-table>
          <div class="d-flex justify-end pt-2">
            <v-pagination
              v-model="page"
              :length="pageCount"
              :total-visible="7"
              @input="changeListPage"
            ></v-pagination>
          </div>
        </v-card>
        <v-dialog
          v-model="dialogForm"
          persistent
          max-width="650px"
        >
          <v-card class="pa-4">
            <university-intake 
              @close="closeDialog"
              :isEdit="isEdit" 
              ref="universityIntakeDialog"
              @update="getDataFromApi"
            />
          </v-card>
        </v-dialog>
      </div>
    </v-container>
  </div>
</template>

<script>
import { get } from 'dot-prop'
import moment from 'moment'
import RegistrationData from '@/apps/dashboard/components/forms/registrationData'
import UniversityIntake from '@/apps/dashboard/components/forms/universityIntake.vue'

import {
  SET_SELECTED_UNIV_INTAKE
} from '../store/mutation-type'
import request from '@/utils/request'
import {
  API_DASH_ACADEMIC_YEAR_LIST
} from '@/constants/apis'
  export default {
    name: 'RegistrationSetting',
    components: {
      RegistrationData,
      UniversityIntake,
    },
    data () {
      return {
        isShowAlert: false,
        alertMsg: '',
        headers: [
          {
            text: 'Gelombang',
            align: 'start',
            value: 'season',
            class: 'black--text subtitle-2'
          },
          { text: 'Tanggal Mulai', value: 'displayStartDate', class: 'black--text subtitle-2' },
          { text: 'Tanggal Berakhir', value: 'displayEndDate', class: 'black--text subtitle-2' },
          { text: 'Status', value: 'status', class: 'black--text subtitle-2' },
          { text: 'Detail', value: 'actions', sortable: false, class: 'black--text subtitle-2' }
        ],
        univIntakeList: [],
        totalList: 0,
        loading: true,
        dialogForm: false,
        isEdit: false,
        options: {},
        limit: 10,
        page: 1,
        pageCount: 1,
        info: {
          desc: 'Apakah anda yakin menghapus?'
        }
      }
    },
    created() {
      this.getDataFromApi()
    },
    methods: {
      isDisabled (itm) {
        const current = new Date().getTime()
        return itm.end_date*1000 < current
      },
      updateOpt (e) {
        if (e.itemsPerPage !== this.limit) {
          this.limit = e.itemsPerPage
          this.page = 1
          this.getDataFromApi()
        }
      },
      handleAlert (isShowAlert, msg) {
        this.isShowAlert = isShowAlert
        this.alertMsg = msg
      },
      getDataFromApi () {
        this.loading = true
        const params = {}
        params.page = this.page
        params.limit = this.limit
        request(API_DASH_ACADEMIC_YEAR_LIST, params).then(res => {
          if (res.success) {
            this.univIntakeList = res.list
            this.univIntakeList.map(itm => {
              itm.displayStartDate = moment(new Date(itm.start_date*1000)).locale('id').format('DD MMMM YYYY')
              itm.displayEndDate = moment(new Date(itm.end_date*1000)).locale('id').format('DD MMMM YYYY')
            })
            this.pageCount = res.pagination.total_page
            this.loading = false
          } else {
            this.alertMsgTable = get(res, 'resultInfo.resultMsg', '')
            this.isShowAlertTable = true
          }
        })
      },
      openDialog (type, item = {}) {
        this.dialogForm = true
        this.isEdit = false
        this.$store.commit(`dashboard/${SET_SELECTED_UNIV_INTAKE}`, item)
        if (type === 'edit') {
          this.isEdit = true
        }
        this.$refs.universityIntakeDialog && this.$refs.universityIntakeDialog.setValue()
      },
      closeDialog () {
        this.dialogForm = false
      },
      changeListPage () {
        this.getDataFromApi()
      },
    },
  }
</script>

<style lang="scss" scoped>
.registration-setting {

}
</style>