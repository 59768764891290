<template>
  <div>
    <v-alert
      dismissible
      type="error"
      v-model="isShowAlert"
    >{{ alertMsg }}</v-alert>
    <validation-observer ref="form">
      <v-form id="form-regist-data" @submit.prevent="submitForm">
        <template v-for="(itm, index) in getRegistrationData">
          <v-row no-gutters :key="`agree${index}`" class="general-form">
            <v-col
              cols="12"
              sm="12"
              md="6"
              lg="6"
              class="general-form__col"
              v-for="(item, idx) in separateColoum(itm.list)"
              :key="`ed${idx}`"
            >
              <template
                v-for="form in item.list"
              >
                <registration-input 
                  :key="form.title" 
                  :form="form"
                />
              </template>
            </v-col>
          </v-row>
          <v-divider v-if="index !== 3" :key="`agreeDiv${index}`" class="mb-4" ></v-divider>
        </template>
        <div class="d-flex justify-end">
          <v-btn 
            color="primary"
            :min-width="160"
            type="submit"
            form="form-regist-data"
            large
          >
            SIMPAN
          </v-btn>
        </div>
      </v-form>
    </validation-observer>
    <dialog-info ref="dialogInfoSuccess" :info="infoSuccess"/>
    <dialog-info ref="dialogInfoInvalid" :info="infoInvalid"/>
    <dialog-confirm ref="dialogConfirmSubmitData" :info="info" :action="handleSubmit"/>
  </div>
</template>

<script>
// Libs
import { ValidationObserver, extend } from 'vee-validate'
import { get } from 'dot-prop'

// Utils
import loadscreen from '@/utils/common/loadScreen'
import request from '@/utils/request'

// Constants
import {
  API_DASH_FINANCIAL_UPDATE
} from '@/constants/apis'
import { required } from 'vee-validate/dist/rules'
import RegistrationInput from '@/components/common/registrationInput'
import DialogConfirm from '@/components/common/DialogConfirm'
import DialogInfo from '@/components/common/DialogInfo'

  export default {
    name: 'RegistrationData',
    components: {
      ValidationObserver,
      RegistrationInput,
      DialogConfirm,
      DialogInfo,
    },
    data () {
      return {
        isShowAlert: false,
        alertMsg: '',
        info: {
          desc: 'Anda yakin data yang anda masukan sudah benar?'
        },
        infoSuccess: {
          title: 'dialog.success',
          desc: 'dialog.success.form',
          btn: 'dialog.success.btn'
        },
        infoInvalid: {
          title: 'dialog.invalid',
          desc: 'dialog.invalid.form',
          btn: 'dialog.success.btn'
        },
      }
    },
    computed: {
      getRegistrationData () {
        const registData = get(this.$store.state, 'dashboard.registrationData.fileRegistrationData', [])
        return this.getData(registData)
      }
    },
    created () {
      this.$store.dispatch('dashboard/getRegistrationData')
    },
    mounted () {
    },
    methods: {
      getData (item) {
        const data = []
        item.map(itm => {
          if (!data[itm.group-1]) {
            const list = []
            list.push(itm)
            data.push({list})
          } else {
            data[itm.group-1].list.push(itm)
          }
        })
        return data
      },
      separateColoum (list = []) {
        const median = Math.ceil(list.length/2)
        return [{
          list: list.slice(0, median)
        }, {
          list: list.slice(median, 10)
        }]
      },
      submitForm () {
        this.$refs.form.validate().then(success => {
          if (!success) {
            this.$refs.dialogInfoInvalid && this.$refs.dialogInfoInvalid.show()
            setTimeout(() => {
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
              });
            }, 500)
          } else {
            // success
            this.$refs.dialogConfirmSubmitData && this.$refs.dialogConfirmSubmitData.show()
          }
        })
      },
      async handleSubmit () {
        loadscreen.show()
        const data = get(this.$store.state, 'dashboard.registrationData.fileRegistrationData', [])
        const params = {}
        data.map(itm => {
          if (itm.type === 'number') {
            params[itm.fields] = parseFloat(itm.value)
          } else {
            params[itm.fields] = itm.path
          }
        })
        const res = await request(API_DASH_FINANCIAL_UPDATE, params)
        if (!res.success) {
          this.alertMsg = get(res, 'resultInfo.resultMsg', '')
          this.isShowAlert = true
        } else {
          this.$refs.dialogInfoSuccess && this.$refs.dialogInfoSuccess.show()
        }
        loadscreen.hide()
      },
    },
  }
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';
</style>
