<template>
  <div class="form-univ-intake">
    <v-alert
      dismissible
      type="error"
      v-model="isShowAlert"
    >{{ alertMsg }}</v-alert>
    <validation-observer ref="formUnivIntake" v-slot="{ invalid }">
      <v-form id="form-university-intake" @submit.prevent="verifySubmit">
        <v-row no-gutters class="general-form">
          <v-col
            cols="12"
            sm="12"
            md="12"
            lg="12"
            class="general-form__col"
          >
            <validation-provider
              v-slot="{ errors }"
              name="Gelombang"
              rules="required"
            >
              <p class="title-14 mb-2">Gelombang</p>
              <!-- <v-select
                v-model="univIntake"
                :items="univIntakeOpt"
                :error-messages="errors"
                dense
                outlined
              ></v-select> -->
              <v-text-field
                placeholder="Gelombang"
                v-model="univIntake"
                :error-messages="errors"
                clearable
                type="text"
                outlined
                dense
              ></v-text-field>
            </validation-provider>
            <v-menu
              v-model="menuStart"
              :close-on-content-click="false"
              max-width="290"
            >
              <template v-slot:activator="{ on, attrs }">
                <validation-provider
                  v-slot="{ errors }"
                  name="Tanggal Mulai"
                  rules="required"
                >
                  <p class="title-14 mb-2">Tanggal Mulai</p>
                  <v-text-field
                    :value="computedDateFormattedMomentjs(startDate)"
                    placeholder="Tanggal Mulai"
                    :error-messages="errors"
                    prepend-icon="mdi-calendar"
                    readonly
                    clearable
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="startDate = ''"
                  ></v-text-field>
                </validation-provider>
              </template>
              <v-date-picker
                v-model="startDate"
                @change="menuStart = false"
              ></v-date-picker>
            </v-menu>
            <v-menu
              v-model="menuEnd"
              :close-on-content-click="false"
              max-width="290"
            >
              <template v-slot:activator="{ on, attrs }">
                <validation-provider
                  v-slot="{ errors }"
                  name="Tanggal Berakhir"
                  rules="required"
                >
                  <p class="title-14 mb-2">Tanggal Berakhir</p>
                  <v-text-field
                    :value="computedDateFormattedMomentjs(endDate)"
                    placeholder="Tanggal Berakhir"
                    :error-messages="errors"
                    prepend-icon="mdi-calendar"
                    readonly
                    clearable
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="endDate = ''"
                  ></v-text-field>
                </validation-provider>
              </template>
              <v-date-picker
                v-model="endDate"
                @change="menuEnd = false"
              ></v-date-picker>
            </v-menu>
            <validation-provider
              v-slot="{ errors }"
              name="Status"
              rules="required"
              v-if="isEdit"
            >
              <p class="title-14 mb-2">Status</p>
              <v-select
                v-model="status"
                :items="statusOpt"
                :error-messages="errors"
                dense
                outlined
              ></v-select>
            </validation-provider>
          </v-col>
        </v-row>
        <div class="d-flex justify-end">
          <v-btn 
            outlined
            :min-width="120"
            large
            class="mr-2"
            @click="closeDialog"
          >
            BATAL
          </v-btn>
          <v-btn 
            color="primary"
            :min-width="120"
            type="submit"
            form="form-university-intake"
            large
            :disabled="isDisabled || invalid"
          >
            SIMPAN
          </v-btn>
        </div>
      </v-form>
    </validation-observer>
    <dialog-confirm ref="dialogConfirm" :info="info" :action="handleSubmit"/>
  </div>
</template>

<script>
// Libs
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import { get } from 'dot-prop'
import moment from 'moment'

// Utils
import loadscreen from '@/utils/common/loadScreen'
import request from '@/utils/request'

// Constants
import {
  API_DASH_ACADEMIC_YEAR_UPDATE,
  API_DASH_ACADEMIC_YEAR_CREATE
} from '@/constants/apis'
import { required } from 'vee-validate/dist/rules'
import DialogConfirm from '../../../../components/common/DialogConfirm'

  export default {
    name: 'UniversityIntakeForm',
    components: {
      ValidationObserver,
      ValidationProvider,
      DialogConfirm,
    },
    props: {
      item: {
        type: Object,
        default: () => {}
      },
      isEdit: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        univIntake: '',
        univIntakeOpt: ['Gelombang 1', 'Gelombang 2', 'Gelombang 3', 'Gelombang 4'],
        startDate: '',
        endDate: '',
        menuStart: false,
        menuEnd: false,
        status: 'active',
        info: {
          desc: 'Anda yakin untuk menambah gelombang pendaftaran?'
        },
        statusOpt: ['active', 'inactive'],
        alertMsg: '',
        isShowAlert: false
      }
    },
    computed: {
      isDisabled () {
        return !this.univIntake || !this.startDate || !this.endDate || this.isError
      },
      selectedUnivIntake () {
        return get(this.$store.state, 'dashboard.selectedUnivIntake', {})
      }
    },
    mounted () {
      extend('required', required)
      if (this.isEdit) {
        this.setValue()
      }
    },
    methods: {
      setValue () {
        const sDate = get(this.selectedUnivIntake, 'start_date', '')
        const eDate = get(this.selectedUnivIntake, 'end_date', '')
        this.univIntake = get(this.selectedUnivIntake, 'season', '')
        this.startDate = sDate ? moment(new Date(sDate*1000)).format('YYYY-MM-DD') : ''
        this.endDate = eDate ? moment(new Date(eDate*1000)).format('YYYY-MM-DD') : ''
        this.status = get(this.selectedUnivIntake, 'status', 'active')
      },
      computedDateFormattedMomentjs (date) {
        return date ? moment(new Date(date)).locale('id').format('dddd, DD MMMM YYYY') : ''
      },
      verifySubmit () {
        if (this.$refs.dialogConfirm) {
          this.$refs.dialogConfirm.show()
        } else {
          this.handleSubmit()
        }
      },
      async handleSubmit () {
        this.$emit('handleAlert', false, '')
        loadscreen.show()
        let apiUrl = API_DASH_ACADEMIC_YEAR_CREATE
        let params = {
          start_date: Date.parse(new Date(this.startDate))/1000 ? Date.parse(new Date(this.startDate))/1000 : 0,
          end_date: Date.parse(new Date(this.endDate))/1000 ? Date.parse(new Date(this.endDate))/1000 : 0,
          season: this.univIntake
        }
        if (this.isEdit) {
          apiUrl = API_DASH_ACADEMIC_YEAR_UPDATE
          params.status = this.status
          params.id = get(this.selectedUnivIntake, 'id', '')
        }
        const res = await request(apiUrl, params)
        if (res.success) {
          this.resetForm()
          this.$emit('close')
          this.$emit('update')
        } else {
          this.alertMsg = get(res, 'resultInfo.resultMsg', '')
          this.isShowAlert = true
        }
        loadscreen.hide()
      },
      closeDialog () {
        this.resetForm()
        this.$emit('close')
      },
      resetForm () {
        if (this.$refs.formUnivIntake){
          this.univIntake = ''
          this.startDate = ''
          this.endDate = ''
          this.status = 'active'
          this.alertMsg = ''
          this.isShowAlert = false
          this.$nextTick(() => {
            this.$refs.formUnivIntake.reset()
          })
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';
.form-univ-intake {
  .title-14 {
    font-size: 14px;
    font-weight: bold;
  }
}
</style>
